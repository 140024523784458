import {
  BooleanInput,
  ReferenceInput,
  SimpleForm,
  NumberInput,
  useNotify,
  useRedirect,
  SelectInput,
  required,
  useUpdate,
  Edit,
} from 'react-admin'
import { ECostChange } from '../CostChanges/types'
import { EValue, EVariant } from '../Variants/types'
import { valuesMapper } from '../Variants/consts'
import { MARKETPLACE_VARIANTS } from '@/services/api/routes'
import { TMarketplaceVariant } from '@/modules/Marketplace/types'

export const MarketplaceEdit = () => {
  const [update] = useUpdate()

  const notify = useNotify()
  const redirect = useRedirect()
  const handleSubmit = (values: Partial<TMarketplaceVariant>) => {
    update(
      'marketplace-variants',
      { id: values[EVariant.Id], data: valuesMapper(values), previousData: values },
      {
        mutationMode: 'pessimistic',
        onSuccess: () => {
          notify('Marketplace Variants updated', {
            type: 'success',
          })
          redirect(MARKETPLACE_VARIANTS())
        },
        onError: (error) => {
          notify(`${error}`, { type: 'error' })
        },
      },
    )
  }

  return (
    <Edit title="Edit Marketplace" redirect="list">
      <SimpleForm onSubmit={handleSubmit} sanitizeEmptyValues>
        <NumberInput
          source={'price'}
          label="Price"
          validate={(value) => (Number.isInteger(value) || value === null ? undefined : 'Price must be an integer')}
          fullWidth
        />

        <ReferenceInput source={ECostChange.VariantId} reference="variants" perPage={1000}>
          <SelectInput
            optionText={(record) =>
              `#${record[EVariant.Id]} - ${record[EVariant.Value]
                .map(
                  (v: { [EValue.EnName]: string; [EValue.EnValue]: string }) => v[EValue.EnName] || v[EValue.EnValue],
                )
                .join(' | ')}`
            }
            validate={required()}
            fullWidth
          />
        </ReferenceInput>

        <ReferenceInput
          source={'marketplaceId'}
          reference="marketplace"
          perPage={1000}
          sort={{ field: 'name', order: 'ASC' }}
        >
          <SelectInput optionText={(record) => record.name} validate={required()} fullWidth />
        </ReferenceInput>

        <BooleanInput source={'active'} fullWidth />
      </SimpleForm>
    </Edit>
  )
}
