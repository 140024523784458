import { Fragment, useState } from 'react'
import {
  List,
  Datagrid,
  TextField,
  ImageField,
  ChipField,
  ArrayField,
  NumberField,
  WithRecord,
  WrapperField,
  BooleanField,
  SelectInput,
  TopToolbar,
  FilterButton,
  ExportButton,
  CreateButton,
  SingleFieldList,
  Button,
  Confirm,
  BulkExportButton,
  BulkDeleteButton,
  ReferenceInput,
  BulkActionProps,
  useRedirect,
} from 'react-admin'
import { Box } from '@mui/material'
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined'
import FileUploadIcon from '@mui/icons-material/UploadFile'
import PermMediaOutlinedIcon from '@mui/icons-material/PermMediaOutlined'
import CloudSyncIcon from '@mui/icons-material/CloudSync'
import { tokens } from '@/Layout/theme'
import { EVariant, EValue, activeStatusList, reservedList } from '@/modules/Variants/types'
import { EProvider, EType } from '@/modules/Products/types'
import { ImportCSVModal } from '@/components/ImportCSVModal'
import { LabelField } from '@/components/LabelField'
import { providerChoices, typeChoices } from '@/modules/Products/consts'
import { Can } from '@/context/AbilityContext'
import { ability } from '@/context/ability'
import { BulkStatusActionButton } from '@/components/BulkStatusActionButton'
import { VariantbulkImage } from '@/components/VariantBulkImage'
import DateTimeField from '@/components/DateTimeField'

export const choices = [
  {
    id: 1,
    name: EType.BUNDLE,
  },
  {
    id: 2,
    name: EType.VOUCHER,
  },
]

export const VariantsList = () => {
  const [open, setOpen] = useState(false)
  const [imageModalOpen, setImageModalOpen] = useState(false)
  const [brandId, setBrandId] = useState<null | number>(null)
  const colors = tokens()
  const redirect = useRedirect()

  const handleCloseModal = () => setOpen(false)
  const handleConfirm = () => setOpen(false)

  const handleCloseImageModal = () => setImageModalOpen(false)
  const handleConfirmImageModal = () => setImageModalOpen(false)

  const variantsFilters = [
    <ReferenceInput
      source={'brandId'}
      reference={'brands'}
      perPage={1000}
      key={'brandId'}
      label="Brand Name"
      sort={{ field: 'enName', order: 'ASC' }}
      alwaysOn
    >
      <SelectInput onChange={(e) => setBrandId(e.target.value)} optionText="enName" label="Brand Name" />
    </ReferenceInput>,

    <ReferenceInput
      source={'productId'}
      reference={'products'}
      perPage={1000}
      key={'productId'}
      sort={{ field: 'enName', order: 'ASC' }}
      label="Product"
      filter={brandId ? { brandId } : undefined}
    >
      <SelectInput
        optionText={(record) => ` ${brandId ? `${record?.brand?.enName} | ` : ''}${record.enName}`}
        label="Product"
      />
    </ReferenceInput>,

    <SelectInput
      label="Provider"
      source={'providerType'}
      key={'providerType'}
      choices={providerChoices}
      optionValue="name"
    />,
    <SelectInput label="Type" source={'productType'} key={'productType'} choices={typeChoices} optionValue="name" />,
    <SelectInput source="isActive" label="Active Status" key="isActive" choices={activeStatusList} />,
    <SelectInput source="isReserved" label="Is Reserved" key="isReserved" choices={reservedList} />,
  ]

  const VariantsActions = () => (
    <TopToolbar>
      <FilterButton />
      <Can I="createVariant" a="variants">
        <CreateButton />
      </Can>
      <Button onClick={() => redirect('multi-uploads')} color="primary" label="Multi Uploads">
        <CloudSyncIcon />
      </Button>
      <ExportButton />
      <Button onClick={() => setImageModalOpen(true)} label="Image Bulk Update">
        <PermMediaOutlinedIcon />
      </Button>
      <Can I="createVouchersBulk" a="vouchers">
        <Button onClick={() => setOpen(true)} label="Import vouchers from .csv">
          <FileUploadIcon />
        </Button>
      </Can>
    </TopToolbar>
  )

  const VariantBulkActionButtons = (props: BulkActionProps) => (
    <Fragment>
      <Can I="bulkUpdate" a="variants">
        <BulkStatusActionButton
          resource="variants"
          selectedIds={props.selectedIds}
          url="/dashboard/variants/bulk-update"
          actionKey="isActive"
          options={[
            { value: true, label: 'Activate' },
            { value: false, label: 'Deactivate' },
          ]}
        />

        <BulkStatusActionButton
          resource="variants"
          selectedIds={props.selectedIds}
          url="/dashboard/variants/bulk-update"
          actionKey="isReserved"
          options={[
            { value: true, label: 'Reserved' },
            { value: false, label: 'Not Reserved' },
          ]}
        />
      </Can>

      <BulkExportButton />
      <Can I="deleteVariant" a="variants">
        <BulkDeleteButton />
      </Can>
    </Fragment>
  )

  return (
    <Can I="getAll" a="variants">
      <List filters={variantsFilters} actions={<VariantsActions />}>
        <Confirm
          isOpen={open}
          title="Upload CSV"
          content={<ImportCSVModal handleClose={handleCloseModal} />}
          cancel=""
          confirm="Close"
          ConfirmIcon={() => <ErrorOutlinedIcon />}
          CancelIcon={() => null}
          onConfirm={handleConfirm}
          onClose={handleCloseModal}
        />
        <Confirm
          isOpen={imageModalOpen}
          title="Multi Image Upload"
          content={<VariantbulkImage handleClose={handleCloseImageModal} />}
          cancel=""
          confirm="Close"
          ConfirmIcon={() => <ErrorOutlinedIcon />}
          CancelIcon={() => null}
          onConfirm={handleConfirmImageModal}
          onClose={handleCloseImageModal}
        />
        <Datagrid
          rowClick={ability.can('updateVariant', 'variants') ? 'edit' : false}
          bulkActionButtons={<VariantBulkActionButtons />}
        >
          <NumberField source={EVariant.Id} textAlign="left" />
          <WithRecord
            label="Value"
            render={(record) => (
              <ArrayField source={EVariant.Value} sortable={false}>
                <SingleFieldList sx={{ dispaly: 'flex', flexDirection: 'column', padding: '8px 0' }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <TextField
                      sx={{
                        color: colors.accent.emerald,
                      }}
                      source={record.product.type === EType.BUNDLE ? EValue.EnName : EValue.EnValue}
                    />
                    <TextField
                      sx={{
                        color: colors.accent.pink,
                      }}
                      source={record.product.type === EType.BUNDLE ? EValue.ArName : EValue.ArValue}
                    />
                  </div>
                </SingleFieldList>
              </ArrayField>
            )}
          />
          <WithRecord
            label="Stock"
            render={(record) => (
              <NumberField
                source={EVariant.Stock}
                sortable={false}
                sx={{
                  color: record[EVariant.Stock] === 0 ? colors.redAccent[500] : colors.neutral[900],
                }}
              />
            )}
          />
          <NumberField source={EVariant.Cost} sortable />
          <BooleanField source={EVariant.IsActive} sortable={false} />
          <BooleanField source={EVariant.IsReserved} sortable={false} />
          <TextField
            source={`${EVariant.Product}.${EValue.Brand}.${EValue.EnName}`}
            label="Brand Name"
            sortable={false}
          />

          <TextField source={`${EVariant.Product}.${EValue.EnName}`} label="Product Name" sortable={false} />

          <TextField source={`${EVariant.Product}.${EValue.ArName}`} label="Product Name(AR)" sortable={false} />

          <WrapperField label="Variant Image" sortable={false}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ImageField
                source={`${EVariant.VariantImage}`}
                sx={{
                  '& img': {
                    maxWidth: 50,
                    maxHeight: 50,
                    objectFit: 'cover !important',
                    borderRadius: '50%',
                  },
                  display: 'flex',
                  marginRight: '8px',
                }}
              />
            </div>
          </WrapperField>
          <NumberField source={EVariant.MarketPlaceCount} label="Activated in Marketplaces" textAlign="left" sortable />
          <NumberField
            source={`${EVariant.Product}.${EValue.Id}`}
            textAlign="left"
            label="Product Id"
            sortable={false}
          />
          <ChipField
            sx={{
              backgroundColor: colors.accent.pink,
              color: colors.neutral[0],
              padding: 0,
            }}
            source={`${EVariant.Product}.${EValue.Type}`}
            label="Type"
            sortable={false}
          />

          <LabelField source={`${EVariant.Product}.${EValue.Provider}`} sortable={false} />
          <WithRecord
            label="Provider Metadata"
            render={(record) => {
              if (record.product.provider === EProvider.ASIACELL) {
                return (
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" color={colors.accent.pink}>
                      ID:&nbsp;
                      <NumberField source={`${EVariant.ProviderMetaData}.id`} sortable={false} />
                    </Box>

                    <TextField source={`${EVariant.ProviderMetaData}.name`} sortable={false} />
                  </Box>
                )
              } else if (record.product.provider === EProvider.MINTROUTE) {
                return (
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" color={colors.accent.pink}>
                      ID:&nbsp;
                      <TextField source={`${EVariant.ProviderMetaData}.id`} sortable={false} />
                    </Box>
                    <Box display="flex">
                      Min:&nbsp;
                      <NumberField source={`${EVariant.ProviderMetaData}.min`} sortable={false} />
                    </Box>
                    <Box display="flex">
                      Max:&nbsp;
                      <NumberField source={`${EVariant.ProviderMetaData}.max`} sortable={false} />
                    </Box>
                  </Box>
                )
              } else return <div />
            }}
          />

          <NumberField source={EVariant.Order} textAlign="left" sortable />

          <DateTimeField source={EVariant.CreatedAt} />

          <DateTimeField source={EVariant.UpdatedAt} />
        </Datagrid>
      </List>
    </Can>
  )
}
