import { FC } from 'react'
import { DateField, DateFieldProps } from 'react-admin'

const DateTimeField: FC<DateFieldProps> = (props) => (
  <DateField
    {...props}
    showTime
    options={{
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    }}
  />
)

export default DateTimeField
