import {
  BooleanInput,
  Edit,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  WithRecord,
  minLength,
  regex,
  required,
} from 'react-admin'

import { NO_WHITESPACE } from '@/consts/regexp'
import { EBrand } from './types'

export const BrandsEdit = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm>
      <TextInput
        source={EBrand.EnName}
        label="English Name"
        validate={[minLength(1), regex(NO_WHITESPACE, 'No leading spaces allowed')]}
        required
        fullWidth
      />
      <TextInput
        source={EBrand.ArName}
        label="Arabic Name"
        validate={[minLength(1), regex(NO_WHITESPACE, 'No leading spaces allowed')]}
        required
        fullWidth
      />

      <TextInput
        source={EBrand.Logo}
        label="Logo Url"
        validate={[regex(NO_WHITESPACE, 'No leading spaces allowed')]}
        required
        fullWidth
      />

      <NumberInput
        source={EBrand.Order}
        label="Order"
        validate={(value) => (Number.isInteger(value) ? undefined : 'Order must be an integer')}
        fullWidth
        required
      />
      <BooleanInput source={EBrand.IsActive} fullWidth />
      <WithRecord
        render={(record) => (
          <ReferenceInput
            source={EBrand.SubCategoryId}
            reference="sub-categories"
            perPage={1000}
            sort={{ field: 'enName', order: 'ASC' }}
          >
            <SelectInput
              optionText={EBrand.EnName}
              validate={required()}
              defaultValue={record[EBrand.SubCategoryId]}
              fullWidth
            />
          </ReferenceInput>
        )}
      />
    </SimpleForm>
  </Edit>
)
