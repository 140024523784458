import {
  List,
  Datagrid,
  TextField,
  ImageField,
  NumberField,
  WithRecord,
  WrapperField,
  ReferenceField,
  Button,
  Identifier,
  useRedirect,
  BulkExportButton,
  BulkDeleteButton,
  useRefresh,
  ReferenceInput,
  SelectInput,
  TopToolbar,
  CreateButton,
  ExportButton,
  BulkActionProps,
} from 'react-admin'
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined'
import { LabelField } from '@/components/LabelField'
import { Box } from '@mui/material'
import { activeStatusList, EBrand } from '@/modules/Brands/types'
import { ESubCategory } from '@/modules/SubCategories/types'
import { tokens } from '@/Layout/theme'
import { PRODUCTS } from '@/services/api/routes'
import { Can } from '@/context/AbilityContext'
import { ability } from '@/context/ability'
import { Fragment } from 'react'
import { BulkStatusActionButton } from '@/components/BulkStatusActionButton'
import DateTimeField from '@/components/DateTimeField'

export const BrandsList = () => {
  const colors = tokens()
  const redirect = useRedirect()
  const refresh = useRefresh()

  const handleRedirect =
    ({ id }: { id: Identifier }) =>
    (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault()
      event.stopPropagation()
      redirect(
        'list',
        encodeURI(
          `${PRODUCTS()}?displayedFilters={"brandId":true}&filter={"brandId":"${id}"}&order=ASC&page=1&perPage=10&sort=id`,
        ),
      )
    }

  const brandFilters = [
    <ReferenceInput
      source={EBrand.Ids}
      reference={'brands'}
      perPage={1000}
      key={1}
      label="Brand Name"
      sort={{ field: 'enName', order: 'ASC' }}
      alwaysOn
    >
      <Can I="findOne" a="brands">
        <SelectInput optionText="enName" label="Brand Name" />
      </Can>
    </ReferenceInput>,

    <ReferenceInput
      source={'subCategoryId'}
      reference={'sub-categories'}
      perPage={1000}
      key={'subCategoryId'}
      label="Sub Category"
      sort={{ field: 'enName', order: 'ASC' }}
      alwaysOn
    >
      <SelectInput optionText="enName" label="Sub Category" />
    </ReferenceInput>,

    // Dropdown for Active Status with "Available" and "Not Available" options
    <SelectInput alwaysOn source="isActive" label="Active Status" key="isActive" choices={activeStatusList} />,
  ]

  const ListActions = () => (
    <TopToolbar>
      <Can I="create" a="brands">
        <CreateButton />
      </Can>
      <ExportButton />
    </TopToolbar>
  )

  const BrandBulkActionButtons = (props: BulkActionProps) => (
    <Fragment>
      <Can I="bulkUpdate" a="brands">
        <BulkStatusActionButton
          resource="brands"
          selectedIds={props.selectedIds}
          url="/dashboard/brands/bulk-status-update"
          actionKey="isActive"
          options={[
            { value: true, label: 'Activate' },
            { value: false, label: 'Deactivate' },
          ]}
        />
      </Can>
      <BulkExportButton />
      <Can I="delete" a="brands">
        <BulkDeleteButton mutationOptions={{ onMutate: refresh }} />
      </Can>
    </Fragment>
  )

  return (
    <Can I="findAll" a="brands">
      <List filters={brandFilters} actions={<ListActions />}>
        <Datagrid
          rowClick={ability.can('update', 'brands') ? 'edit' : false}
          bulkActionButtons={<BrandBulkActionButtons />}
        >
          <NumberField source={EBrand.Id} textAlign="left" />

          <WrapperField label="Brand" sortBy={EBrand.EnName}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ImageField
                source={EBrand.Logo}
                sx={{
                  '& img': {
                    maxWidth: 50,
                    maxHeight: 50,
                    objectFit: 'cover !important',
                    borderRadius: '50%',
                  },
                  display: 'flex',
                  marginRight: '8px',
                }}
              />
              <TextField source={EBrand.EnName} label="English Name" />
            </Box>
          </WrapperField>

          <TextField source={EBrand.ArName} label="Arabic Name" sortBy="arName" />
          <NumberField source={EBrand.MarketPlaceCount} label="Activated in Marketplaces" textAlign="left" sortable />
          <NumberField source={EBrand.Order} textAlign="left" sortable />

          <LabelField source={EBrand.IsActive} label="IsActive" sortable={false} />
          <ReferenceField source={EBrand.SubCategoryId} reference="sub-categories" sortable={false} label="Subcategory">
            <TextField source={ESubCategory.EnName} />
          </ReferenceField>
          <DateTimeField source={EBrand.CreatedAt} />
          <DateTimeField source={EBrand.UpdatedAt} />
        </Datagrid>
      </List>
    </Can>
  )
}
