import {
  Create,
  BooleanInput,
  ReferenceInput,
  SimpleForm,
  NumberInput,
  useCreate,
  useNotify,
  useRedirect,
  SelectInput,
  required,
} from 'react-admin'
import { ECostChange } from '../CostChanges/types'
import { EValue, EVariant, TValues } from '../Variants/types'
import { valuesMapper } from '../Variants/consts'
import { MARKETPLACE_VARIANTS } from '@/services/api/routes'

export const MarketplaceCreate = () => {
  const [create] = useCreate()
  const notify = useNotify()
  const redirect = useRedirect()
  const handleSubmit = (values: TValues) => {
    create(
      'marketplace-variants',
      { data: valuesMapper(values) },
      {
        onSuccess: () => {
          notify('Variant created', {
            type: 'success',
          })
          redirect(MARKETPLACE_VARIANTS())
        },
        onError: (error) => {
          notify(`${error}`, { type: 'error' })
        },
      },
    )
  }

  return (
    <Create title="Create new marketplace variant" redirect="list">
      <SimpleForm onSubmit={handleSubmit} sanitizeEmptyValues>
        <NumberInput
          source={'price'}
          label="Price"
          validate={(value) => (Number.isInteger(value) || value === null ? undefined : 'Price must be an integer')}
          fullWidth
        />

        <ReferenceInput source={ECostChange.VariantId} reference="variants" perPage={1000}>
          <SelectInput
            optionText={(record) =>
              `#${record[EVariant.Id]} - ${record[EVariant.Value]
                .map(
                  (v: { [EValue.EnName]: string; [EValue.EnValue]: string }) => v[EValue.EnName] || v[EValue.EnValue],
                )
                .join(' | ')}`
            }
            validate={required()}
            fullWidth
          />
        </ReferenceInput>

        <ReferenceInput
          sort={{ field: 'name', order: 'ASC' }}
          source={'marketplaceId'}
          reference="marketplace"
          perPage={1000}
        >
          <SelectInput optionText={(record) => record.name} validate={required()} fullWidth />
        </ReferenceInput>

        <BooleanInput source={'active'} fullWidth />
      </SimpleForm>
    </Create>
  )
}
